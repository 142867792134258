*,
*::after,
*::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html,
body {
    padding: 0;
    margin: 0;
    overflow-x: clip;
}

body {
    font-family: "Montserrat", sans-serif;
}

/* cyrillic-ext */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/cyrillic-ext.woff2") format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/cyrillic.woff2") format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/vietnamese.woff2") format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
        U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/latin-ext.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/latin.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

h1 {
    margin: 0;
    font-size: clamp(28px, 2.5vw, 40px);
    text-align: center;
    margin-bottom: 10px;
}

hr {
    background-color: #888888;
    height: 4px;
    width: 80px;
    margin: 0 auto;
    margin-bottom: 50px;
}

h2 {
    font-weight: 700;
    line-height: 1.5;
    font-size: clamp(20px, 2.5vw, 24px);
    margin: 0;
}

p {
    color: #000;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

a {
    line-height: inherit;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    color: #000;
}

a:hover {
    text-decoration: none;
    color: #1c1918;
}

img {
    max-width: 100%;
}

img,
svg {
    vertical-align: middle;
}

button {
    border: none;
    outline: none;
    padding: 0;
    background-color: inherit;
}

#logo {
    width: 100%;
    color: #1c1917;
}

#logo svg {
    fill: currentColor;
}

#header {
    background: rgba(255, 255, 255, 1);
    border-bottom: 1px solid #efefef;
    /* backdrop-filter: blur(10px); */
    box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
    height: 80px;
    display: flex;
    align-items: center;
    z-index: 15;
    position: fixed;
    inset: 0;
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    transition-property: transform, opacity, visibility;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
}

#header.hidden {
    transform: translateY(-40px);
    opacity: 0;
    visibility: hidden;
}

#header .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: 1660px;
    width: 100%;
    margin: auto;
    padding: 0 30px;
}

#header .nav .navbar {
    display: flex;
    width: 100%;
    height: 100%;
}

#header .nav .navbar .menu {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: end;
    width: 100%;
    font-weight: 600;
    height: 100%;
}

#header .nav .navbar .menu li {
    height: 100%;
    display: flex;
    align-items: center;
    position: revert;
}

#header .nav .navbar .menu li a {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    transition: all 1s ease-in-out;
}

#header .nav .navbar .menu li:last-child a::after,
#header .nav .navbar .menu li:last-child a::before {
    display: none;
}

#header .nav .navbar .menu li a::after,
#header .nav .navbar .menu li a::before {
    content: "";
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 30px;
    height: 100%;
    background-color: black;
    transform: translateX(-50%) scaleX(0);
    transition: transform 0.15s ease-in-out;
    height: 2px;
}

#header .nav .navbar .menu li:hover>a::after,
#header .nav .navbar .menu li:hover>a::before {
    transform: translateX(-50%) scaleX(1);
}

#contact:after {
    content: "";
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 30;
    height: 1rem;
    width: 1rem;
    border-radius: 0.25rem;
    background: rgba(239, 239, 239, 1);
    backdrop-filter: blur(10px);
    transform: translate(-50%, -60%) rotate(45deg);
}

#header .nav .navbar .menu li:hover .triangle {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

#header .nav .navbar .menu li .triangle {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fff;
    height: 0;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%);
    width: 0;
    z-index: 22;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-in-out;
    z-index: 12;
}

#header .nav .navbar .menu li:hover .arrow {
    opacity: 1;
    visibility: visible;
}

#header .nav .navbar .menu li .arrow {
    cursor: pointer;
    position: absolute;
    bottom: 25px;
    left: 50%;
    width: 15px;
    transform: translateX(-50%) rotate(90deg);
    opacity: 0;
    visibility: hidden;
    z-index: 12;
}

#header .nav .navbar .menu li .arrow .arrow-right,
.arrow-left {
    background-color: transparent;
    height: 2px;
    position: absolute;
    width: 100%;
}

#header .nav .navbar .menu li .arrow .arrow-right:after,
.arrow-left:after {
    background-color: black;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 0.15s;
}

#header .nav .navbar .menu li .arrow .arrow-right {
    transform: rotate(65deg);
    transform-origin: bottom right;
}

#header .nav .navbar .menu li .arrow .arrow-right:after {
    left: 100%;
    right: 0;
    transition-delay: 0s;
}

#header .nav .navbar .menu li .arrow .arrow-left {
    transform: rotate(-65deg);
    transform-origin: top right;
}

#header .nav .navbar .menu li .arrow .arrow-left:after {
    left: 100%;
    right: 0;
    transform-origin: right;
}

#header .nav .navbar .menu li:hover .arrow .arrow-right:after {
    left: 0;
}

#header .nav .navbar .menu li:hover .arrow .arrow-left:after {
    left: 0;
    transition-delay: 0s;
}

#header .nav .navbar .menu li:hover .custom-sub-menu {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 0%);
}

#header .nav .navbar .menu li .custom-sub-menu {
    background-color: #1c1917;
    color: white;
    max-width: 1660px;
    width: 100%;
    display: flex;
    height: auto;
    left: 50%;
    margin-top: 0;
    opacity: 0;
    padding: 30px;
    position: absolute;
    top: 100%;
    transform: translate(-50%, -15%);
    visibility: hidden;
    white-space: nowrap;
    z-index: 11;
    transition: all 0.2s ease-in-out;
}

#header .nav .navbar .menu li .custom-sub-menu::before,
#header .nav .navbar .menu li .custom-sub-menu::after {
    background-color: inherit;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 0;
}

#header .nav .navbar .menu li .custom-sub-menu::before {
    right: calc(100% - 1px);
}

#header .nav .navbar .menu li .custom-sub-menu::after {
    left: calc(100% - 1px);
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item {
    align-items: flex-end;
    width: 100%;
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .widget-text,
#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .widget-contact {
    font-size: 12px;
    padding-right: 3rem;
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .widget-text h4 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 12px;
    margin-top: 0;
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .widget-text p {
    font-size: 12px;
    font-weight: 300;
    margin: 0;
    color: inherit;
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .widget-contact h4 {
    margin: 4px 0;
    color: inherit;
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .widget-contact h4 a {
    font-size: 16px;
    font-weight: 500;
    color: inherit;
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .follow-us {
    margin-left: auto;
    margin-bottom: 5px;
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .follow-us a {
    color: inherit;
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .follow-us svg {
    fill: currentColor;
}

#header .nav .navbar-burger {
    display: none;
}

/*mobile menu*/
.overflow-hidden {
    overflow: hidden;
}

.menu-open .mobile-menu {
    right: 0px;
}

.mobile-menu {
    position: absolute;
    right: -100%;
    z-index: 100;
    height: 100vh;
    width: 20rem;
    max-width: 100%;
    transition-property: right;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    bottom: 0;
    top: 0;
}

.mobile-menu nav {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    display: flex;
    max-width: 320px;
    flex-direction: column;
    --tw-bg-opacity: 1;
    background-color: rgb(51 51 51 / var(--tw-bg-opacity));
    padding: 1.25rem;
    --tw-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    --tw-shadow-colored: 0 4px 4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.mobile-menu nav .navbar-close {
    margin-left: auto;
    margin-bottom: 20px;
}

.mobile-menu nav .navbar-close button{
    cursor: pointer;
}

.mobile-menu nav .navbar-close + ul {
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.mobile-menu nav .navbar-close+ul>li {
    padding-bottom: 25px;
}

.mobile-menu nav .navbar-close+ul li>a {
    color: inherit;
    font-weight: 700;
    font-size: 1.750rem;
}

.mobile-menu nav .navbar-close+ul li ul {
    padding-left: 20px;
}

.mobile-menu nav .navbar-close+ul li ul li>p {
    color: inherit;
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
}

.mobile-menu nav .navbar-close+ul li ul li>a {
    font-weight: 900;
    font-size: 18;
}

.mobile-menu nav .mobile-social-bar {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding-top: 20px;
    margin-top: auto;
}

.mobile-menu nav .mobile-social-bar a {
    color: white;
}

.mobile-menu nav .mobile-social-bar a svg {
    fill: currentColor;
}

#bg-overlay {
    opacity: 0;
    visibility: hidden;
    z-index: 50;
    background-color: rgb(0 0 0 / 0.8);
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100vh;
    transition: all .3s ease-in-out;
}

#bg-overlay.show {
    opacity: 1;
    visibility: visible;
}

/*filter menu*/
.filter {
    display:flex;
    justify-content: center;
    position:relative;
    margin: 20px 8% 40px;
    opacity: 0;
    transition: transform .9s, opacity .4s;
    transform: translateY(100px);
}

.filter-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.past-filter {
    position:absolute;
    right:0;
    top:50%;
    transform: translateY(-50%);
    color:#000;
    border:1px solid #000;
    border-radius:25px;
    padding:10px 18px;
    cursor:pointer;
}

.past-filter input[type="checkbox"] {
    display: none;
    visibility: hidden;
}

.past-filter label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.past-filter .check {
    position: relative;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
    width: 16px;
    height: 16px;
}

.past-filter .check svg {
    position: relative;
    z-index: 1;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: gray;
    stroke-width: 1.5;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
}

.past-filter .check svg path {
    stroke-dasharray: 60;
    stroke-dashoffset: 0;
}

.past-filter .check svg polyline {
    stroke-dasharray: 22;
    stroke-dashoffset: 66;
}

.past-filter .check:hover svg,
.past-filter input[type="checkbox"]:checked + .check svg {
    stroke: #000;
}

.past-filter input[type="checkbox"]:checked + .check svg path {
    stroke-dashoffset: 60;
    transition: all 0.3s linear;
}

.past-filter input[type="checkbox"]:checked + .check svg polyline {
    stroke-dashoffset: 42;
    transition: all 0.2s linear;
    transition-delay: 0.15s;
}

.filter.show {
    opacity:1;
    transform: translateY(0);
}

.filter-menu li {
    color: black;
    cursor: pointer;
    background: transparent;
    border: 1px solid black;
    border-radius: 25px;
    padding: 10px 18px;
    display: inline-block;
    transition: all .15s ease-in-out;
}

.filter-menu li:hover,
.filter-menu li.current {
    background: black;
    color: white;
}

.filter-item .projects {
    display: none !important;
    transition: all 0.5s ease;
}

.filter-item .projects.active{
    width: 100%;
    display: flex !important;
    height: 100%;
    transition: all 0.5s ease;
}

#wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100vh;
    background-color: #1c1917;
    /* background: url(../images/bg.jpg) no-repeat center; */
    background-size: cover;
    background-attachment: fixed;
    text-align: center;
}

#wrapper .container-md {
    position: relative;
}

#wrapper .container-md img {
    width: 70%;
    height: auto;
    fill: #fff;
    text-align: center;
    margin-bottom: 5%;
}

#wrapper .container-md [data-anim] {
    opacity: 0;
    transform: translateY(100px);
    transition: transform 0.9s, opacity 0.4s;
}

#wrapper .container-md [data-anim].show {
    opacity: 1;
    transform: translateY(0);
}

#wrapper .container-md p[data-anim] {
    transition-delay: 150ms;
}

#wrapper p {
    color: #fff;
    font-size: 18px;
}

#wrapper canvas {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
}

.section-container {
    background-color: #fff;
    position: relative;
    z-index: 2;
}

#services {
    padding: 100px 0;
}

#services .container {
    max-width: 860px;
}

#services .row {
    background-color: #efefef;
    border-radius: 10px;
    flex-wrap: nowrap;
    padding: clamp(20px, 2.5vw, 30px);
    gap: 20px;
    margin-bottom: 40px;
    align-items: center;
    margin: 30px 0px;
}

#services .row .icon {
    background-color: #000;
    border-radius: 50%;
    min-width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

#services .row div+div h2 {
    font-size: clamp(18px, 2.5vw, 22px);
}

#services .row div+div p {
    font-size: 16px;
}

#services .row p {
    margin: 0;
}

.container-md {
    width: 100%;
    max-width: 760px;
    padding: 40px 20px;
}

.container {
    --bs-gutter-x: 60px;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1320px;
    }
}

.row {
    --bs-gutter-x: 60px;
    --bs-gutter-y: 0;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
}

.row>* {
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-top: var(--bs-gutter-y);
}

.col-6 {
    flex: 0 0 auto;
    width: 50%;
}

@media (min-width: 992px) {
    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333%;
    }
}

.mb-5 {
    margin-bottom: 5% !important;
}

.projects {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    padding-bottom: 12%;
}

.projects.even {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.projects.even .content {
    transform: translateX(-100px);
    padding: 0% 0% 0% 8%;
}

.projects.even .mockup {
    margin-right: -15%;
    margin-left: 0;
}

.projects.even .mockup .desktop {
    transform: translateX(100px);
}

.projects.even .mockup .desktop.show {
    transform: translateX(0);
    opacity: 1;
}

.projects.even .mockup .mobile {
    left: -2.85%;
    right: 0;
}

.projects .mockup {
    position: relative;
    width: 70%;
    margin-left: -8%;
}

.projects .mockup .desktop {
    transition: transform 0.9s, opacity 0.4s;
    transform: translateX(-100px);
    opacity: 0;
    width: 90%;
}

.projects .mockup .desktop.show {
    transform: translateX(0) !important;
    opacity: 1;
}

.projects .mockup .mobile {
    position: absolute;
    right: 7.15%;
    top: 45%;
    width: 20%;
    transition: transform 0.9s, opacity 0.4s;
    transform: translateY(100px);
    opacity: 0;
}

.projects .mockup .mobile.show {
    transform: translateY(0);
    opacity: 1;
}

.projects .content {
    transition: transform 0.9s, opacity 0.4s;
    transform: translateX(100px);
    opacity: 0;
    padding: 0% 8% 0% 0%;
    width: 38%;
}

.projects .content .badge {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
}

.projects .content .badge span {
    background-color: #808080;
    border-radius: 15px;
    padding: 5px 10px;
    color: white;
    font-size: 12px;
}

.projects .content .wrap {
    display: flex;
    width: 100%;
    gap: 8px;
}

.projects .content .wrap a img {
    max-width: 160px;
    width: 100%;
}

.projects .content.show {
    transform: translateX(0) !important;
    opacity: 1;
}

.projects .content p {
    font-size: 16px;
    line-height: 1.5;
}

/*our team*/
.item {
    transition: transform 0.9s, opacity 0.4s;
    transform: translateY(100px);
    opacity: 0;
}

.item:nth-child(1) {
    transition-duration: 0.7s;
}

.item:nth-child(2) {
    transition-duration: 0.8s;
}

.item:nth-child(3) {
    transition-duration: 0.9s;
}

.item:nth-child(4) {
    transition-duration: 1s;
}

.item:nth-child(5) {
    transition-duration: 1.1s;
}

.item:nth-child(6) {
    transition-duration: 1.2s;
}

.item.show {
    transform: translateY(0);
    opacity: 1;
}

#our-team {
    padding: 100px 0;
    background-color: #efefef;
}

#our-team .team-member {
    margin-top: 30px;
    text-align: center;
}

#our-team .container {
    max-width: 1200px;
}

#our-team .team-member .image-box img {
    width: 100%;
    border-radius: 10%;
    object-fit: cover;
}

#our-team .team-member .image-box {
    position: relative;
    overflow: hidden;
}

#our-team .team-member .text {
    padding: 13px 0;
}

#our-team .team-member .text h6 {
    margin-bottom: 15px;
    margin-top: 5px;
    font-size: 14px;
}

#our-team .team-member .text span {
    font-size: 16px;
    font-weight: 600;
}

#our-team .team-member .text p {
    font-size: 13px;
    font-weight: 400;
    margin: 0;
}

#our-team .wrapper .row>* {
    padding: 0px 50px 0px 50px;
}

.footer {
    background-color: #1c1917;
    color: white;
    padding: 25px;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
}

.footer img { 
    margin-top: -3px;
}