*, :after, :before {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: clip;
}

body {
  font-family: Montserrat, sans-serif;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/cyrillic-ext.6bee8a4e.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/cyrillic.6470f283.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/vietnamese.bdae1712.woff2") format("woff2");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/latin-ext.9438f3e1.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/latin.a605914d.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

h1 {
  text-align: center;
  margin: 0 0 10px;
  font-size: clamp(28px, 2.5vw, 40px);
}

hr {
  height: 4px;
  width: 80px;
  background-color: #888;
  margin: 0 auto 50px;
}

h2 {
  margin: 0;
  font-size: clamp(20px, 2.5vw, 24px);
  font-weight: 700;
  line-height: 1.5;
}

p {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  line-height: inherit;
  cursor: pointer;
  color: #000;
  text-decoration: none;
  display: inline-block;
}

a:hover {
  color: #1c1918;
  text-decoration: none;
}

img {
  max-width: 100%;
}

img, svg {
  vertical-align: middle;
}

button {
  background-color: inherit;
  border: none;
  outline: none;
  padding: 0;
}

#logo {
  width: 100%;
  color: #1c1917;
}

#logo svg {
  fill: currentColor;
}

#header {
  height: 80px;
  z-index: 15;
  opacity: 1;
  visibility: visible;
  background: #fff;
  border-bottom: 1px solid #efefef;
  align-items: center;
  transition-property: transform, opacity, visibility;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: fixed;
  inset: 0;
  transform: translateY(0);
  box-shadow: 0 2px 29px #0000000d;
}

#header.hidden {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-40px);
}

#header .nav {
  height: 100%;
  max-width: 1660px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 0 30px;
  display: flex;
}

#header .nav .navbar {
  width: 100%;
  height: 100%;
  display: flex;
}

#header .nav .navbar .menu {
  width: 100%;
  height: 100%;
  justify-content: end;
  align-items: center;
  gap: 20px;
  font-weight: 600;
  display: flex;
}

#header .nav .navbar .menu li {
  height: 100%;
  position: revert;
  align-items: center;
  display: flex;
}

#header .nav .navbar .menu li a {
  height: 100%;
  align-items: center;
  transition: all 1s ease-in-out;
  display: flex;
  position: relative;
}

#header .nav .navbar .menu li:last-child a:after, #header .nav .navbar .menu li:last-child a:before {
  display: none;
}

#header .nav .navbar .menu li a:after, #header .nav .navbar .menu li a:before {
  content: "";
  width: 30px;
  height: 100%;
  height: 2px;
  background-color: #000;
  transition: transform .15s ease-in-out;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%)scaleX(0);
}

#header .nav .navbar .menu li:hover > a:after, #header .nav .navbar .menu li:hover > a:before {
  transform: translateX(-50%)scaleX(1);
}

#contact:after {
  content: "";
  z-index: 30;
  height: 1rem;
  width: 1rem;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: #efefef;
  border-radius: .25rem;
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -60%)rotate(45deg);
}

#header .nav .navbar .menu li:hover .triangle {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

#header .nav .navbar .menu li .triangle {
  height: 0;
  width: 0;
  z-index: 22;
  opacity: 0;
  visibility: hidden;
  z-index: 12;
  border-top: 8px solid #fff;
  border-left: 8px solid #0000;
  border-right: 8px solid #0000;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

#header .nav .navbar .menu li:hover .arrow {
  opacity: 1;
  visibility: visible;
}

#header .nav .navbar .menu li .arrow {
  cursor: pointer;
  width: 15px;
  opacity: 0;
  visibility: hidden;
  z-index: 12;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%)rotate(90deg);
}

#header .nav .navbar .menu li .arrow .arrow-right, .arrow-left {
  height: 2px;
  width: 100%;
  background-color: #0000;
  position: absolute;
}

#header .nav .navbar .menu li .arrow .arrow-right:after, .arrow-left:after {
  content: "";
  height: 100%;
  background-color: #000;
  transition: all .15s;
  position: absolute;
  top: 0;
}

#header .nav .navbar .menu li .arrow .arrow-right {
  transform-origin: 100% 100%;
  transform: rotate(65deg);
}

#header .nav .navbar .menu li .arrow .arrow-right:after {
  transition-delay: 0s;
  left: 100%;
  right: 0;
}

#header .nav .navbar .menu li .arrow .arrow-left {
  transform-origin: 100% 0;
  transform: rotate(-65deg);
}

#header .nav .navbar .menu li .arrow .arrow-left:after {
  transform-origin: 100%;
  left: 100%;
  right: 0;
}

#header .nav .navbar .menu li:hover .arrow .arrow-right:after {
  left: 0;
}

#header .nav .navbar .menu li:hover .arrow .arrow-left:after {
  transition-delay: 0s;
  left: 0;
}

#header .nav .navbar .menu li:hover .custom-sub-menu {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%);
}

#header .nav .navbar .menu li .custom-sub-menu {
  color: #fff;
  max-width: 1660px;
  width: 100%;
  height: auto;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  z-index: 11;
  background-color: #1c1917;
  margin-top: 0;
  padding: 30px;
  transition: all .2s ease-in-out;
  display: flex;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -15%);
}

#header .nav .navbar .menu li .custom-sub-menu:before, #header .nav .navbar .menu li .custom-sub-menu:after {
  background-color: inherit;
  content: "";
  height: 100%;
  width: 100vw;
  z-index: 0;
  position: absolute;
  top: 0;
}

#header .nav .navbar .menu li .custom-sub-menu:before {
  right: calc(100% - 1px);
}

#header .nav .navbar .menu li .custom-sub-menu:after {
  left: calc(100% - 1px);
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item {
  width: 100%;
  align-items: flex-end;
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .widget-text, #header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .widget-contact {
  padding-right: 3rem;
  font-size: 12px;
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .widget-text h4 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 600;
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .widget-text p {
  color: inherit;
  margin: 0;
  font-size: 12px;
  font-weight: 300;
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .widget-contact h4 {
  color: inherit;
  margin: 4px 0;
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .widget-contact h4 a {
  color: inherit;
  font-size: 16px;
  font-weight: 500;
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .follow-us {
  margin-bottom: 5px;
  margin-left: auto;
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .follow-us a {
  color: inherit;
}

#header .nav .navbar .menu li .custom-sub-menu .custum-sub-menu-item .follow-us svg {
  fill: currentColor;
}

#header .nav .navbar-burger {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

.menu-open .mobile-menu {
  right: 0;
}

.mobile-menu {
  z-index: 100;
  height: 100vh;
  width: 20rem;
  max-width: 100%;
  transition-property: right;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  right: -100%;
}

.mobile-menu nav {
  max-width: 320px;
  --tw-bg-opacity: 1;
  background-color: rgb(51 51 51 / var(--tw-bg-opacity));
  --tw-shadow: 0 4px 4px #00000040;
  --tw-shadow-colored: 0 4px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  flex-direction: column;
  padding: 1.25rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.mobile-menu nav .navbar-close {
  margin-bottom: 20px;
  margin-left: auto;
}

.mobile-menu nav .navbar-close button {
  cursor: pointer;
}

.mobile-menu nav .navbar-close + ul {
  width: 100%;
  height: 100%;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  display: flex;
}

.mobile-menu nav .navbar-close + ul > li {
  padding-bottom: 25px;
}

.mobile-menu nav .navbar-close + ul li > a {
  color: inherit;
  font-size: 1.75rem;
  font-weight: 700;
}

.mobile-menu nav .navbar-close + ul li ul {
  padding-left: 20px;
}

.mobile-menu nav .navbar-close + ul li ul li > p {
  color: inherit;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
}

.mobile-menu nav .navbar-close + ul li ul li > a {
  font-size: 18px;
  font-weight: 900;
}

.mobile-menu nav .mobile-social-bar {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding-top: 20px;
  display: flex;
}

.mobile-menu nav .mobile-social-bar a {
  color: #fff;
}

.mobile-menu nav .mobile-social-bar a svg {
  fill: currentColor;
}

#bg-overlay {
  opacity: 0;
  visibility: hidden;
  z-index: 50;
  width: 100%;
  height: 100vh;
  background-color: #000c;
  transition: all .3s ease-in-out;
  position: fixed;
  inset: 0;
}

#bg-overlay.show {
  opacity: 1;
  visibility: visible;
}

.filter {
  opacity: 0;
  justify-content: center;
  margin: 20px 8% 40px;
  transition: transform .9s, opacity .4s;
  display: flex;
  position: relative;
  transform: translateY(100px);
}

.filter-menu {
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  display: flex;
}

.past-filter {
  color: #000;
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 25px;
  padding: 10px 18px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.past-filter input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.past-filter label {
  cursor: pointer;
  align-items: center;
  display: inline-flex;
}

.past-filter .check {
  -webkit-tap-highlight-color: transparent;
  width: 16px;
  height: 16px;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.past-filter .check svg {
  z-index: 1;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: gray;
  stroke-width: 1.5px;
  transition: all .2s;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.past-filter .check svg path {
  stroke-dasharray: 60;
  stroke-dashoffset: 0;
}

.past-filter .check svg polyline {
  stroke-dasharray: 22;
  stroke-dashoffset: 66px;
}

.past-filter .check:hover svg, .past-filter input[type="checkbox"]:checked + .check svg {
  stroke: #000;
}

.past-filter input[type="checkbox"]:checked + .check svg path {
  stroke-dashoffset: 60px;
  transition: all .3s linear;
}

.past-filter input[type="checkbox"]:checked + .check svg polyline {
  stroke-dashoffset: 42px;
  transition: all .2s linear .15s;
}

.filter.show {
  opacity: 1;
  transform: translateY(0);
}

.filter-menu li {
  color: #000;
  cursor: pointer;
  background: none;
  border: 1px solid #000;
  border-radius: 25px;
  padding: 10px 18px;
  transition: all .15s ease-in-out;
  display: inline-block;
}

.filter-menu li:hover, .filter-menu li.current {
  color: #fff;
  background: #000;
}

.filter-item .projects {
  transition: all .5s;
  display: none !important;
}

.filter-item .projects.active {
  width: 100%;
  height: 100%;
  transition: all .5s;
  display: flex !important;
}

#wrapper {
  height: 100vh;
  text-align: center;
  background-color: #1c1917;
  background-size: cover;
  background-attachment: fixed;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

#wrapper .container-md {
  position: relative;
}

#wrapper .container-md img {
  width: 70%;
  height: auto;
  fill: #fff;
  text-align: center;
  margin-bottom: 5%;
}

#wrapper .container-md [data-anim] {
  opacity: 0;
  transition: transform .9s, opacity .4s;
  transform: translateY(100px);
}

#wrapper .container-md [data-anim].show {
  opacity: 1;
  transform: translateY(0);
}

#wrapper .container-md p[data-anim] {
  transition-delay: .15s;
}

#wrapper p {
  color: #fff;
  font-size: 18px;
}

#wrapper canvas {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: fixed;
  top: 0;
  left: 0;
}

.section-container {
  z-index: 2;
  background-color: #fff;
  position: relative;
}

#services {
  padding: 100px 0;
}

#services .container {
  max-width: 860px;
}

#services .row {
  background-color: #efefef;
  border-radius: 10px;
  flex-wrap: nowrap;
  align-items: center;
  gap: 20px;
  margin: 30px 0;
  padding: clamp(20px, 2.5vw, 30px);
}

#services .row .icon {
  min-width: 100px;
  height: 100px;
  background-color: #000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

#services .row div + div h2 {
  font-size: clamp(18px, 2.5vw, 22px);
}

#services .row div + div p {
  font-size: 16px;
}

#services .row p {
  margin: 0;
}

.container-md {
  width: 100%;
  max-width: 760px;
  padding: 40px 20px;
}

.container {
  --bs-gutter-x: 60px;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 60px;
  --bs-gutter-y: 0;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col-6 {
  width: 50%;
  flex: none;
}

@media (min-width: 992px) {
  .col-lg-3 {
    width: 25%;
    flex: none;
  }

  .col-lg-4 {
    width: 33.3333%;
    flex: none;
  }
}

.mb-5 {
  margin-bottom: 5% !important;
}

.projects {
  width: 100%;
  align-items: center;
  padding-bottom: 12%;
  display: flex;
  position: relative;
}

.projects.even {
  flex-direction: row-reverse;
  justify-content: space-between;
  display: flex;
}

.projects.even .content {
  padding: 0% 0% 0% 8%;
  transform: translateX(-100px);
}

.projects.even .mockup {
  margin-left: 0;
  margin-right: -15%;
}

.projects.even .mockup .desktop {
  transform: translateX(100px);
}

.projects.even .mockup .desktop.show {
  opacity: 1;
  transform: translateX(0);
}

.projects.even .mockup .mobile {
  left: -2.85%;
  right: 0;
}

.projects .mockup {
  width: 70%;
  margin-left: -8%;
  position: relative;
}

.projects .mockup .desktop {
  opacity: 0;
  width: 90%;
  transition: transform .9s, opacity .4s;
  transform: translateX(-100px);
}

.projects .mockup .desktop.show {
  opacity: 1;
  transform: translateX(0) !important;
}

.projects .mockup .mobile {
  width: 20%;
  opacity: 0;
  transition: transform .9s, opacity .4s;
  position: absolute;
  top: 45%;
  right: 7.15%;
  transform: translateY(100px);
}

.projects .mockup .mobile.show {
  opacity: 1;
  transform: translateY(0);
}

.projects .content {
  opacity: 0;
  width: 38%;
  padding: 0% 8% 0% 0%;
  transition: transform .9s, opacity .4s;
  transform: translateX(100px);
}

.projects .content .badge {
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  display: flex;
}

.projects .content .badge span {
  color: #fff;
  background-color: gray;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 12px;
}

.projects .content .wrap {
  width: 100%;
  gap: 8px;
  display: flex;
}

.projects .content .wrap a img {
  max-width: 160px;
  width: 100%;
}

.projects .content.show {
  opacity: 1;
  transform: translateX(0) !important;
}

.projects .content p {
  font-size: 16px;
  line-height: 1.5;
}

.item {
  opacity: 0;
  transition: transform .9s, opacity .4s;
  transform: translateY(100px);
}

.item:nth-child(1) {
  transition-duration: .7s;
}

.item:nth-child(2) {
  transition-duration: .8s;
}

.item:nth-child(3) {
  transition-duration: .9s;
}

.item:nth-child(4) {
  transition-duration: 1s;
}

.item:nth-child(5) {
  transition-duration: 1.1s;
}

.item:nth-child(6) {
  transition-duration: 1.2s;
}

.item.show {
  opacity: 1;
  transform: translateY(0);
}

#our-team {
  background-color: #efefef;
  padding: 100px 0;
}

#our-team .team-member {
  text-align: center;
  margin-top: 30px;
}

#our-team .container {
  max-width: 1200px;
}

#our-team .team-member .image-box img {
  width: 100%;
  object-fit: cover;
  border-radius: 10%;
}

#our-team .team-member .image-box {
  position: relative;
  overflow: hidden;
}

#our-team .team-member .text {
  padding: 13px 0;
}

#our-team .team-member .text h6 {
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 14px;
}

#our-team .team-member .text span {
  font-size: 16px;
  font-weight: 600;
}

#our-team .team-member .text p {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
}

#our-team .wrapper .row > * {
  padding: 0 50px;
}

.footer {
  color: #fff;
  z-index: 2;
  background-color: #1c1917;
  justify-content: center;
  padding: 25px;
  display: flex;
  position: relative;
}

.footer img {
  margin-top: -3px;
}

/*# sourceMappingURL=index.6fc8300e.css.map */
